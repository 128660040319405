





























































































import { Component, Mixins } from 'vue-property-decorator'
import { View } from 'client-website-ts-library/plugins'
import ContactForm from '@/components/ContactForm.vue'

@Component({
  components: {
    ContactForm,
  },
})

export default class Resources extends Mixins(View) {
}
